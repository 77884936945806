// for npm roboto-fontface package (to load local files)
$roboto-font-path: "~roboto-fontface/fonts" !default;
@import "~roboto-fontface/css/roboto/sass/roboto-fontface";

// for npm material-icons package (to load local files)
$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';


@import url('https://cdn.quilljs.com/1.2.2/quill.snow.css');
@import url('https://cdn.quilljs.com/1.2.2/quill.bubble.css');

// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/leaflet/dist/leaflet.css";

@import "app/theme/styles/base";
@import "app/theme/styles/theme";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/rtl";
@import "app/theme//styles/variables";

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color: $primary;
}
.cursor-pointer{
    cursor: pointer;
}

.mat-icon {
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    font-size: 20px;
    height: 22px !important;
    width: 24px !important;
}
.mat-dialog-title {
    color: $primary-dark;
    margin: 0 !important;
}
.primary-color {
    color: $primary-dark;
}
.bg-primary-dark {
    background-color: $primary-dark;
    color: #FFFFFF;
}
.header-bar-tab {
    display: flex;
    border-top: 3px solid $primary-dark;
    background-color: white;
    width: 100%;
}
.header-bar-tab1 {
    display: flex;
    background-color: white;
    width: 100%;
}

.header-bar-title {
    width: auto;
    //height: 30px;
    background-color: $primary-dark;
    display: inline-block;
    padding: 10px;
    padding-top: 8px !important;
    color: white;
}
.parallelogram {
    display: inline-block;
    border-top: 40px solid $primary-dark;
    border-right: 20px solid transparent;
    // height: 20px;
    width: 5px;
}
.sub-menu .mat-button {
    padding-left: 5px !important;
}
.sub-menu .sub-menu .mat-button {
    padding-left: 0 !important;
}
.sub-menu.show {
    max-height: 100% !important;
}
.meta-data-table-center {
    table {
        border-collapse: collapse;
        width: 100%;
      }
      
      th {
        padding: 8px;
        text-align: right;
      }
      td {
        padding: 8px;
        text-align: left;
      }
}
.meta-data-table-left {
    table {
        border-collapse: collapse;
        width: 100%;
      }
      
      th {
        width: 100%;
        text-align: left;
      }
      td {
        padding: 8px;
        text-align: left;
      }
}
.meta-data-table {
    table {
        border-collapse: collapse;
        width: 100%;
        border: 1px solid #ddd;
        background-color: #eaf6f4;
      }
      
      th {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }
      td {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }
}

.ngx-datatable.material {
    .datatable-header {
        background: #00796B;

        .datatable-header-cell {
            background: #00796B;
            color: #fff;
        }

        .datatable-icon-sort-unset::before {
            opacity: 1;
            margin-left: 6px;
            font-size: 14px;
        }

        .datatable-header-cell-wrapper {
            font-size: 14px;
        }
    }
}

.is-invalid{
    font-size: 75%;
    color: #f44336;
}
.row-color{
    background-color: #f44336;
}
.capitalize{
    text-transform: capitalize;
}

.pdfViewer{
    width: 0 !important;
}
.disabled{
    pointer-events: none;
}

.snackbar-wrapper-error {
    background: #CA0B00;
    color: #fff;
}

.snackbar-wrapper-success {
    background: #22bb33;
    color: #fff;
}

.mat-simple-snackbar {
    font-size: 15px;
}


// mat-table customizations
.mat-header-cell {
    background: #00796b;
    color: #fff;
}

.mat-sort-header-arrow {
    color: #fff;
}

.tmf-docslist-filter-wrap {
    width: 75%;
    margin: 0 auto;
}

// Utility Classes
.gv-mt-0 {
   margin-top: 0 !important;
}

.gv-mth {
    margin-top: 0.5rem !important;
}

.gv-mt1 {
    margin-top: 1rem !important;
}

.gv-mt2 {
    margin-top: 2rem !important;
}

.gv-mt3 {
    margin-top: 3rem !important;
}

.gv-mbh {
    margin-bottom: 0.5rem !important;
}

.gv-mb1 {
    margin-bottom: 1rem !important;
}

.gv-mb2 {
    margin-bottom: 2rem !important;
}

.gv-mb3 {
    margin-bottom: 3rem !important;
}

.gv-mrh {
    margin-right: 0.5rem !important;
}

.gv-mr1 {
    margin-right: 1rem !important;
}

.gv-mr2 {
    margin-right: 2rem !important;
}

.gv-mr3 {
    margin-right: 3rem !important;
}

.required-marker{
    color: red;
    font-weight: bold;
    margin-left: 3px;
    font-size: 1.2em;
}

.mat-column-action {
    display: flex;
    justify-content: space-around;
}

.mat-column-action > div {
    width: 100%;
    text-align: right;
}

.mat-column-sno {
    flex: 0 0 6%;
}

.mat-column-documentName {
    flex: 1 0 30%;
}

.mat-column-documentVersion {
    flex: 0 0 12%;
}

.mat-column-documentStatus {
    flex: 0 0 25%;
}

.mat-column-documentDate {
    flex: 0 0 10%;
}

.mat-column-action {
    flex: 0 0 10%;
}

// .mat-column-fielNoteComments {
//     flex: 0 0 25%;
// }

.over-auto {
    overflow: auto;
}