@import "variables";

.bg-primary { 
    background: $primary;
    color: #FFFFFF;
}
.btn-primary{
    background: $primary;
    color: #FFFFFF;
}

.btn-info {
    background: $info;
    color: #fff;
}

.btn-warning {
    background: $warning-dark;
    color: #111
}

.btn-danger {
    background: $danger;
    color: #fff;
}

.mat-toolbar{
    padding: 0 $flex-distance;
    min-height: $toolbar-height;
}
.mat-toolbar.mat-primary {
    background: $primary;
    color: #fff;
}
.mat-toolbar-row, 
.mat-toolbar-single-row{
    height: $toolbar-height;
}
.mat-raised-button.small-logo{
    color:  $primary;
}
.horizontal-menu{
    background: $primary-dark !important;
}
.horizontal-menu-item {
    .horizontal-sub-menu{
        background: $primary-dark;
    }
}
.breadcrumb{
    a{
        color:  $primary;
    }
    .breadcrumb-item+.breadcrumb-item:before{
        color: #cfcfcf;
    }
}
.active-link{
    border-radius: 0 !important;
    background-color: $primary;
    color: #fff;
}
